import { IShipmentStatusModel } from '../../api/TrackingApi/IShipmentStatusModel';
import { ShipmentStatus } from '../../api/Common/ShipmentStatus';
import { ParcelEntityType } from '../../api/Common/ParcelEntityType';
import { ShipmentType } from '../../api/Common/ShipmentType';
import { CarrierType } from '../../api/Common/CarrierType';
import { TrackingStatuses } from '../../api/TrackingApi/TrackingStatuses';
import { ITrackingHistory } from '../../api/TrackingApi/ITrackingHistory';

import { TApiRequest } from "../../hooks/useServiceApi";
import { config } from '../../config';
import i18n from '../../i18n/I18nManager';

export function GetCurrentStatusRequest(trackingNumber: string, recaptchaToken: string): TApiRequest {
    return {
        url: `${config.apiUrl}${config.trackUrl}/currentStatus?trackingNumber=${trackingNumber}`,
        init: {
            mode: "cors",
            headers: {
                "Recaptcha-Token": recaptchaToken,
            },
          }
    }
}
export interface ShipmentStepResult {
    Steps: ShipmentStep[];
    ExternalTrackingLoadingError: boolean;
    HasExtraHistory: boolean;
}

export interface ShipmentStep {
    IsCurrent: boolean;
    IsCompleted: boolean;
    IsFuture: boolean,
    Description: string;
    Key: ShipmentStatus | string;
    Date: Date | undefined;
    ExtraHistory?: boolean;
}

export function GetShipmentSteps(currentStatus: IShipmentStatusModel): ShipmentStepResult {
    let steps = DefaultShipmentSteps;
    if (currentStatus.status == ShipmentStatus.Cancelled) {
        steps = CanceledShipmentSteps;
    }
    else if (currentStatus.shipmentType == ShipmentType.FulfillmentCenterWarehouse) {
        steps = FromFCShipmentSteps;
    }
    else if (currentStatus.isFulfillment || currentStatus.shipmentType == ShipmentType.ParcelsAtReturnsWarehouseLabel) {
        steps = FCShipmentSteps;
    }
    else if ((currentStatus.parcelType == ParcelEntityType.UpsShipment || currentStatus.parcelType == ParcelEntityType.ParcelConsolidation)) {
        if (currentStatus.shipFromCountry == UAReceivingWarehouse) {
            steps = ExpressUAShipmentSteps;
        } else {
            steps = DirectShipmentSteps;
            if (currentStatus.status == ShipmentStatus.Created) {
                currentStatus.status = ShipmentStatus.OnTheWay;
            }
        }
    }
    else if (currentStatus.shipmentType == ShipmentType.UpsConsolidationPlus) {
        steps = UpsConsolidationPlusShipmentSteps;
    }
    else if (currentStatus.parcelType == ParcelEntityType.ConsolidationPackageGroup && currentStatus.shipmentType == ShipmentType.ConsolidationDpd) {
        steps = CpgUaDpdShipmentSteps;
    }
    else if (currentStatus.parcelType == ParcelEntityType.ConsolidationPackageGroup && currentStatus.shipFromCountry == UAReceivingWarehouse) {
        steps = CPGUAShipmentSteps;
    }
    else if (currentStatus.parcelType == ParcelEntityType.ConsolidationPackageGroup) {
        steps = CPGShipmentSteps;
    }

    return getShipmentSteps(currentStatus, steps);
}

const DefaultShipmentSteps = [
    ShipmentStatus.Created,
];
const CanceledShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.Cancelled,
];
const ExpressUAShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.AcceptedInSendingWarehouse,
    ShipmentStatus.SendedFromUkraineWarehouse,
    ShipmentStatus.OnTheWay
];
const UpsConsolidationPlusShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.AcceptedInSendingWarehouse,
    ShipmentStatus.ReadyToShip,
    ShipmentStatus.SendedFromUkraineWarehouse,
    ShipmentStatus.AcceptedInReceivingWarehouse,
    ShipmentStatus.ShippedToCustomer,
    ShipmentStatus.OnTheWay
];
const CPGUAShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.AcceptedInSendingWarehouse,
    ShipmentStatus.SendedFromUkraineWarehouse,
    ShipmentStatus.AcceptedInReceivingWarehouse,
    ShipmentStatus.ShippedToCustomer,
    ShipmentStatus.OnTheWay
];
const CpgUaDpdShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.AcceptedInSendingWarehouse,
    ShipmentStatus.SendedFromUkraineWarehouse,
    ShipmentStatus.OnTheWay
];
const CPGShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.AcceptedInReceivingWarehouse,
    ShipmentStatus.ShippedToCustomer,
    ShipmentStatus.OnTheWay
];
const FCShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.ShippedToCustomer,
    ShipmentStatus.OnTheWay
];
const DirectShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.OnTheWay
];
const FromFCShipmentSteps = [
    ShipmentStatus.Created,
    ShipmentStatus.ShippedFromReturnsWarehouse,
    ShipmentStatus.OnTheWay
];

const UAReceivingWarehouse = "UA";

function getShipmentSteps(currentStatus: IShipmentStatusModel, shipmentSteps: ShipmentStatus[]) {
    let isLastWbStep = true;

    let shipmentStatuses: ShipmentStatus[];
    let currentStepIndex = shipmentSteps.indexOf(currentStatus.status);
    if (currentStepIndex == -1 && currentStatus.status != ShipmentStatus.ReturnsWarehouse) {
        shipmentStatuses = [...DefaultShipmentSteps];
        shipmentStatuses.push(currentStatus.status);
        currentStepIndex = shipmentStatuses.length - 1;
    } else {
        shipmentStatuses = [...shipmentSteps]
        isLastWbStep = currentStepIndex == (shipmentSteps.length - 1);
    }

    let result: ShipmentStepResult = {
        Steps: [],
        ExternalTrackingLoadingError: false,
        HasExtraHistory: false
    };

    if (isLastWbStep) {
        addExternalSteps(result, currentStatus);
        if (result.Steps?.length > 0) {
            currentStepIndex += result.Steps?.length;
        }
    }

    if (currentStatus.status == ShipmentStatus.ReturnsWarehouse) {
        shipmentStatuses.push(currentStatus.status);
        currentStepIndex = shipmentStatuses.length - 1;
    }

    result.Steps.unshift(...shipmentStatuses.map((stepKey, index) =>
        getShipmentStep(stepKey, currentStatus, index, currentStepIndex)));

    return result;
}

function getShipmentStep(key: ShipmentStatus, currentStatus: IShipmentStatusModel, stepIndex: number, currentStepIndex: number): ShipmentStep {
    return {
        Key: key,
        Description: i18n.getText(`ShipmentStatus.${ShipmentStatus[key]}`),
        IsCurrent: stepIndex == currentStepIndex,
        IsCompleted: stepIndex < currentStepIndex,
        IsFuture: stepIndex > currentStepIndex,
        Date: getStepDate(key, currentStatus)
    };
}

function addExternalSteps(result: ShipmentStepResult, currentStatus: IShipmentStatusModel) {
    result.ExternalTrackingLoadingError = IsExternalTrackingRequired(currentStatus);

    let externalTracking = currentStatus.externalTracking;
    if (!externalTracking)
    {
        return;
    }

    if (currentStatus.externalTracking.currentStatus?.status == TrackingStatuses.Created) {
		result.ExternalTrackingLoadingError = false;
        return;
    }

    if (externalTracking.trackingHistory?.length > 0) {
        result.HasExtraHistory = externalTracking.trackingHistory.length > 1;
        result.ExternalTrackingLoadingError = false;

        let currentStepIndex = externalTracking.trackingHistory.length - 1;
        externalTracking.trackingHistory
            .forEach((element, index) => {
                if (IsAllowedExternalStatus(element.status)) {
                    result.Steps.push(getExternalShipmentStep(element, index, currentStepIndex));
                }
            });
    }
    else if (externalTracking.currentStatus?.statusDetails && IsAllowedExternalStatus(externalTracking.currentStatus.status)) {
        result.ExternalTrackingLoadingError = false;
        result.Steps.push(getExternalShipmentStep(externalTracking.currentStatus, 0, 0));
    }
}

function IsAllowedExternalStatus(status: TrackingStatuses) {
    return status != TrackingStatuses.Created;
}

function IsExternalTrackingRequired(currentStatus: IShipmentStatusModel): boolean {
    return currentStatus.status != ShipmentStatus.Cancelled &&
        currentStatus.status != ShipmentStatus.ReturnsWarehouse &&
        currentStatus.carrierType != CarrierType.Dpd &&
        currentStatus.parcelType != ParcelEntityType.NovaPoshtaParcels;
}

function getExternalShipmentStep(history: ITrackingHistory, stepIndex: number, currentStepIndex: number): ShipmentStep {
    return {
        Key: `External.${stepIndex}.${history.status.toString()}`,
        Description: getHistoryDescription(history),
        IsCurrent: stepIndex == currentStepIndex,
        IsCompleted: stepIndex < currentStepIndex,
        IsFuture: stepIndex > currentStepIndex,
        Date: history.statusDate,
        ExtraHistory: stepIndex < currentStepIndex
    };
}

function getHistoryDescription(history: ITrackingHistory): string {
    return history.statusDetails +
        (!history.location ?
            '' :
            ` · ${[history.location.city, history.location.state, history.location.country].filter(x => x).join(", ")}`);
}

function getStepDate(key: ShipmentStatus, currentStatus: IShipmentStatusModel): Date | undefined {
    if (key == ShipmentStatus.Created) {
        return currentStatus.createdDate;
    }

    if (key == ShipmentStatus.AcceptedInSendingWarehouse) {
        return currentStatus.storeDate;
    }

    if (key == ShipmentStatus.SendedFromUkraineWarehouse) {
        return currentStatus.sentDate;
    }

    if (key == ShipmentStatus.AcceptedInReceivingWarehouse) {
        return currentStatus.receivedDate;
    }

    if (key == ShipmentStatus.ShippedToCustomer) {
        return currentStatus.receivedDate;
    }
}
